<template>
  <!-- Skeleton -->
  <div v-if="!project"></div>

  <!-- Project -->
  <k-layout v-else class="tw-bg-white">
    <div v-if="!isPublished" class="tw-bg-red-alt tw-py-3">
      <k-container>
        <div class="tw-w-full tw-flex tw-items-center tw-text-red">
          <k-icon name="info" class="tw-flex-shrink-0" />
          <p class="tw-font-bold tw-ml-2">
            {{ $t('project.detail.status.not_available') }}
          </p>
        </div>
      </k-container>
    </div>

    <k-container class="tw-mt-12" hide-aside-mobile>
      <template #default>
        <div class="tw-flex tw-flex-col tw-items-center tw-space-y-1 sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-2">
          <span
            v-if="project.isNew"
            class="tw-rounded-sm tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-green-alt tw-text-green"
          >
            {{ $t('project.detail.status.new') }}
          </span>

          <span
            v-if="project.remoteOfficePercentage"
            class="tw-rounded-sm tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-yellow-alt tw-text-yellow"
          >
            {{ $t('project.detail.status.remote') }} {{ project.remoteOfficePercentage }}%
          </span>

          <span
            v-if="project.language"
            class="tw-rounded-sm tw-px-2 tw-py-1 tw-uppercase tw-font-mono tw-leading-none tw-bg-red-alt tw-text-red"
          >
            {{ project.language }}
          </span>
        </div>

        <h1 class="tw-heading-1 tw-mt-2">{{ project.name }}</h1>

        <template v-if="project.partner && project.partner.branch">
          <p class="tw-mt-2">{{ project.partner.branch }}</p>
        </template>

        <dl class="tw-mt-8 tw-bg-white tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-shadow-glow">
          <div class="tw-p-4">
            <dt class="tw-font-semibold tw-text-sm tw-text-gray-500">{{ $t('project.detail.salary') }}</dt>
            <dd class="tw-text-base tw-mt-2 tw-flex tw-flex-col">
              <div v-if="isContract" class="tw-grid tw-grid-cols-3 tw-gap-1">
                <span>{{ $t('project.detail.contract') }}</span>
                <span class="tw-col-span-2">{{ formatRateContract }}</span>
                <!--                                TODO: nemame-->
                <!--                                <v-tooltip v-if="project.rateContractNote" top max-width="600" color="rgba(0, 0, 0, 0.75)">-->
                <!--                                    <template v-slot:activator="{ on }">-->
                <!--                                        <span v-on="on" class="tw-flex tw-items-center">-->
                <!--                                            <k-icon name="info" class="tw-text-gray-900" size="20" />-->
                <!--                                        </span>-->
                <!--                                    </template>-->
                <!--                                    <p>-->
                <!--                                        {{ project.rateContractNote }}-->
                <!--                                    </p>-->
                <!--                                </v-tooltip>-->
              </div>

              <div v-else-if="isTPP" class="tw-grid tw-grid-cols-3 tw-gap-1">
                <span>{{ $t('project.detail.tpp') }}</span>
                <span class="tw-col-span-2">{{ formatRateTPP }}</span>
                <!--                                TODO: nemame-->
                <!--                                <v-tooltip v-if="project.rateTPPNote" top max-width="600" color="rgba(0, 0, 0, 0.75)">-->
                <!--                                    <template v-slot:activator="{ on }">-->
                <!--                                        <span v-on="on" class="tw-flex tw-items-center">-->
                <!--                                            <k-icon name="info" class="tw-text-gray-900" size="20" />-->
                <!--                                        </span>-->
                <!--                                    </template>-->
                <!--                                    <p>-->
                <!--                                        {{ project.rateTPPNote }}-->
                <!--                                    </p>-->
                <!--                                </v-tooltip>-->
              </div>
              <div v-else class="tw-grid tw-grid-cols-3 tw-gap-1">
                <template v-if="formatRateContract">
                  <span>{{ $t('project.detail.contract') }}</span>
                  <span class="tw-col-span-2">{{ formatRateContract }}</span>
                </template>

                <template v-if="formatRateTPP">
                  <span>{{ $t('project.detail.tpp') }}</span>
                  <span class="tw-col-span-2">{{ formatRateTPP }}</span>
                </template>
              </div>
            </dd>
          </div>

          <div class="tw-p-4 tw-border-gray-100 tw-border-t md:tw-border-l md:tw-border-t-0">
            <dt class="tw-font-semibold tw-text-sm tw-text-gray-500">{{ $t('project.detail.location') }}</dt>
            <dd class="tw-text-base tw-mt-2">{{ project.city || '–' }}</dd>
          </div>

          <div class="tw-p-4 tw-border-gray-100 tw-border-t md:tw-border-l md:tw-border-t-0">
            <dt class="tw-font-semibold tw-text-sm tw-text-gray-500">{{ $t('global.experience') }}</dt>
            <dd class="tw-text-base tw-mt-2">{{ requiredLevels }}</dd>
          </div>
        </dl>

        <section class="tw-rounded-sm tw-mt-16">
          <div :class="{ 'tw-opacity-50': !isPublished }">
            <h2 class="tw-heading-4 tw-text-gray-900">{{ $t('project.detail.subtitles.position') }}</h2>

            <p
              id="project-description" class="tw-mt-6 tw-prose tw-max-w-full tw-text-gray-700 tw-leading-8"
              v-html="project.description"
            />

            <ka-project-technologies
              v-if="project.expertises && project.expertises.length"
              :technologies="project.expertises"
              class="tw-mt-12"
            />
          </div>
        </section>
      </template>

      <template #aside>
        <template v-if="isPublished">
          <div v-if="alreadyApplyForTheJob">
            <div class="tw-bg-gray-100 tw-relative tw-rounded-sm tw-w-full tw-py-4 tw-text-center">
              <span class="tw-text-gray-500 tw-font-bold tw-text-base">
                {{ $t('project.detail.status.already_responded') }}
              </span>
            </div>

            <p class="tw-mt-4 tw-text-center tw-w-4/5 tw-mx-auto">
              {{ $t('project.detail.descriptions.team_contacts_you') }}
            </p>
          </div>
          <div v-else class="tw-relative tw-rounded-sm">
            <div
              class="tw-inset-0 tw-absolute tw-rounded-md tw-animate-btn-ping tw-w-full tw-h-full tw-bg-gray-300 tw-delay-1000"
            />
            <k-button @click="handleApplyForTheJob" class="tw-relative tw-w-full" id="gtm_apply_for_job">
              <span class="tw-font-bold tw-text-base">{{ $t('project.detail.actions.respond_to_offer') }}</span>
            </k-button>
          </div>

          <div class="tw-mt-6" v-if="project.responsibleHR">
            <project-detail-hr-contact-info :person="project.responsibleHR" />
          </div>
        </template>
      </template>
    </k-container>

    <k-container class="tw-mt-16 tw-mb-24">
      <template #default>
        <div class="tw-border-0 tw-border-t tw-border-gray-200 tw-border-solid tw-pt-16">
          <dl class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-6">
            <div>
              <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t('project.detail.project_duration') }}</dt>
              <dd class="tw-mt-2 tw-text-base">
                <span v-if="project.projectLengthInMonths">
                  <span v-if="projectLengthInYears > 0">
                    {{ projectLengthInYears }}
                    {{ $tc('global.time.years', projectLengthInYears > 4 ? 2 : projectLengthInYears > 1 ? 1 : 0) }}
                  </span>
                  <span v-else>
                    {{ project.projectLengthInMonths }}
                    {{
                      $tc(
                        'global.time.months',
                        project.projectLengthInMonths > 4 ? 2 : project.projectLengthInMonths > 1 ? 1 : 0
                      )
                    }}
                  </span>
                  <span v-if="project.durationExtension">
                    {{ $t('project.detail.duration_extension') }}
                  </span>
                </span>
                <span v-else>
                  -
                </span>
              </dd>
            </div>

            <div>
              <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t('project.detail.start_date') }}</dt>
              <dd class="tw-mt-2 tw-text-base">
                <span>
                  {{ toLocaleDateString(project.jobStartDate) }}
                </span>
              </dd>
            </div>

            <div>
              <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t('project.detail.type') }}</dt>
              <dd class="tw-mt-2 tw-text-base">
                <span v-if="isTPP">{{ $t('project.detail.tpp') }}</span>
                <span v-else-if="!isTPP && !isContract">
                  {{ $t('project.detail.tpp') }} / {{ $t('project.detail.contract') }}
                </span>
                <span v-else-if="isContract">{{ $t('project.detail.contract') }}</span>
              </dd>
            </div>

            <div>
              <dt class="tw-text-sm tw-font-bold tw-text-gray-500">{{ $t('project.detail.project_language') }}</dt>
              <dd class="tw-mt-2 tw-text-base">
                <span>{{ project.language }}</span>
              </dd>
            </div>
          </dl>
        </div>

        <div class="tw-mt-16" v-if="isPublished && !alreadyApplyForTheJob">
          <div class="tw-relative">
            <div
              class="tw-inset-0 tw-rounded-md tw-absolute tw-animate-btn-ping tw-w-full tw-h-full tw-bg-gray-300 tw-delay-1000"
            />
            <k-button
              color="secondary"
              @click="handleApplyForTheJob"
              class="tw-w-full"
              custom-class="tw-py-4"
              id="gtm_apply_for_job_2"
            >
              <span class="tw-font-bold tw-text-base">{{ $t('project.detail.actions.respond_to_offer') }}</span>
            </k-button>
          </div>

          <div class="tw-mt-6" v-if="project.responsibleHR">
            <project-detail-hr-contact-info :person="project.responsibleHR" />
          </div>
        </div>
      </template>

      <template #aside>
        <template v-if="isPublished">
          <div class="tw-flex tw-space-x-2">
            <!--                        <k-button type="button" v-if="profile">-->
            <!--                            <span>📨&nbsp;{{ isSaved ? $t("global.titles.saved") : $t("global.actions.save") }}</span>-->
            <!--                        </k-button>-->

            <k-button
              :to="{ name: 'referral-form-project', params: { id: project.id } }"
              class="tw-w-full sm:tw-w-auto"
            >
              🕵️‍♀️&nbsp;{{ $t('global.actions.recommend') }}
            </k-button>
          </div>

          <div class="tw-mt-8">
            <p class="tw-font-bold tw-w-full tw-text-gray-800">{{ $t('global.actions.share') }}</p>
            <div class="tw-space-x-2 tw-mt-4">
              <a
                :href="shareFacebookLink"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-full tw-text-gray-800 tw-inline-flex tw-items-center tw-no-underline tw-justify-center hover:tw-bg-gray-200 tw-transition tw-duration-150"
              >
                <k-icon size="16" name="facebook" />
              </a>
              <a
                :href="shareLinkedInLink"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-full tw-text-gray-800 tw-inline-flex tw-items-center tw-no-underline tw-justify-center hover:tw-bg-gray-200 tw-transition tw-duration-150"
              >
                <k-icon size="16" name="linkedin" />
              </a>
              <a
                :href="shareMailLink"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-12 tw-h-12 tw-bg-gray-100 tw-rounded-full tw-text-gray-800 tw-inline-flex tw-items-center tw-no-underline tw-justify-center hover:tw-bg-gray-200 tw-transition tw-duration-150"
              >
                <k-icon size="16" name="email" />
              </a>
            </div>
          </div>
        </template>
      </template>
    </k-container>

    <!--        TODO: implementuje sa na BE strane -->
    <!--        &lt;!&ndash; Similar Jobs &ndash;&gt;-->
    <!--        <section v-if="similarProjects && similarProjects.length > 0" class="tw-bg-gray-100 tw-mt-16 tw-pt-20 tw-pb-20">-->
    <!--            <k-container>-->
    <!--                <template #default>-->
    <!--                    <section>-->
    <!--                        <div class="tw-flex tw-justify-between tw-items-center">-->
    <!--                            <h1 class="tw-heading-3">{{ $t("project.detail.similar_job") }} 📌</h1>-->
    <!--                            <k-link :to="{ name: 'project-index' }" class="tw-text-gray-500 hover:tw-text-gray-700">-->
    <!--                                {{ $t("project.detail.actions.all_jobs") }} ›-->
    <!--                            </k-link>-->
    <!--                        </div>-->

    <!--                        <div class="tw-mt-8 tw-space-y-6">-->
    <!--                            <project-preview :project="project" v-for="project in similarProjects" :key="project.id" />-->
    <!--                        </div>-->
    <!--                    </section>-->
    <!--                </template>-->

    <!--                <template #aside>-->
    <!--                    <div class="tw-relative tw-overflow-hidden tw-bg-koderia tw-rounded-sm">-->
    <!--                        <img class="tw-absolute tw-w-full tw-inset-0 tw-z-0 tw-object-contain tw-object-center" src="./../assets/cv-teaser-bg-new.svg" alt="" />-->
    <!--                        <div class="tw-relative tw-flex tw-flex-col tw-pt-10 tw-pl-10 tw-pr-10">-->
    <!--                            <p class="tw-text-white tw-text-2xl tw-leading-tight">-->
    <!--                                <span v-html="$t('project.detail.descriptions.modern_resume')" />-->
    <!--                                <br />-->
    <!--                                <span class="tw-font-bold">{{ $t("project.detail.free") }}.</span>-->
    <!--                            </p>-->
    <!--                            <k-link :to="{ name: 'cv-landing-page' }" class="tw-no-underline tw-mt-8">-->
    <!--                                <k-button>-->
    <!--                                    {{ $t("global.actions.create_resume") }}-->
    <!--                                </k-button>-->
    <!--                            </k-link>-->
    <!--                            <img class="tw-w-full tw-h-full tw-object-cover tw-mt-8" src="./../assets/cv-teaser-new.svg" alt="" />-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </template>-->
    <!--            </k-container>-->
    <!--        </section>-->

    <v-dialog v-if="showInterestDialog" v-model="showInterestDialog" max-width="648">
      <project-detail-interest :project="project" @close="showInterestDialog = false" />
    </v-dialog>
  </k-layout>
</template>

<script>
import Cookies from 'js-cookie'
import urlcat from 'urlcat'
import { mapGetters } from 'vuex'
import 'dayjs/locale/sk'
import 'dayjs/locale/en'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import KaProjectTechnologies from '@web/components/ka-project-technologies'

import ProjectDetailHrContactInfo from './../components/project-detail-hr-contact-info.vue'
import ProjectDetailInterest from './../components/project-detail-interest.vue'
// import ProjectPreview from "./../components/project-preview-new.vue";
import { DEFAULT_LOCALE } from '@web/constants/language'
import { formatDateDistance } from '@web/utils/date'
import KButton from '@web/components/core/k-button'
import projectMixin from '@web/projects/projectMixin'
import dayjs from 'dayjs'

dayjs.extend(localizedFormat)

export default {
  components: {
    KButton,
    // KaLabel,
    // MatchMeter,
    // KaRadarWrapper,
    ProjectDetailHrContactInfo,
    KaProjectTechnologies,
    ProjectDetailInterest
    // ProjectPreview
  },
  mixins: [projectMixin],
  data() {
    return {
      isSaved: false,
      showInterestDialog: false
    }
  },
  created() {
    this.showInterestDialog = this.$route.hash === '#apply'

    const { id } = this.$route.params

    this.$store.dispatch('NEW_PROJECTS/fetchProjectDetail', { id })
    this.$vuetify.goTo(0)
  },
  watch: {
    project: {
      immediate: true,
      handler(project) {
        if (project && project.id) {
          window.history.replaceState('', '', this.url)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'USER/getUserProfile',
      project: 'NEW_PROJECTS/getProjectDetail',
      isLogged: 'AUTH/getIsUserSignedIn',
      isEmailVerified: 'AUTH/getIsUserEmailVerified'
    }),
    isPublished() {
      return this.project?.states.some(state => ['PUBLISHED', 'PUBLISHED_HIRING_POSTPONED'].includes(state.value)) || false
    },
    appLocale() {
      return this.$store.getters.appLocale
    },
    alreadyApplyForTheJob() {
      const { id } = this.$route.params
      const { jobApplications } = this.profile || {}

      return !!jobApplications?.find(jobApplication => jobApplication.jobId === id)
    },
    url() {
      const pathPrefix = this.appLocale === DEFAULT_LOCALE ? '' : `${ this.appLocale }/`

      return urlcat(window.location.origin,
        `${ pathPrefix }${ this.$t('route_alias.project_detail') }`, {
          id: this.project.id,
          ...(this.$route.query || {}),
          location: this.project.location,
          type: this.project.type,
          technologies: this.project.technologies
        })
    },
    shareFacebookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${ encodeURIComponent(this.url) }`
    },
    shareLinkedInLink() {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${ encodeURIComponent(this.url) }`
    },
    shareMailLink() {
      return `mailto:?subject=${ this.project.name }&body=${ this.project.name }%0A%0A${ this.url }`
    },
    projectLengthInYears() {
      const years = this.project.projectLengthInMonths / 12
      const rest = this.project.projectLengthInMonths % 12

      return rest === 0 ? years : 0
    }
  },
  filters: {
    toRelativeDistance(date) {
      try {
        return formatDateDistance(date)
      } catch {
        return ''
      }
    }
  },
  methods: {
    toLocaleDateString(date) {
      try {
        return dayjs(date).locale(this.appLocale).format('LL')
      } catch {
        return ''
      }
    },
    doAuth(type) {
      const { id } = this.$route.params
      const redirectAfterLoginTo = {
        name: 'project-detail',
        params: { id },
        hash: '#apply'
      }

      Cookies.set('redirect.after_login', JSON.stringify(redirectAfterLoginTo))

      this.$router.push({ name: type })
    },
    handleApplyForTheJob() {
      if (this.isLogged && this.isEmailVerified) {
        this.showInterestDialog = true

        return
      }

      if (this.isLogged && !this.isEmailVerified) {
        this.$store.commit('setDialog', { name: 'verification', value: true })

        return
      }

      this.doAuth('auth-signup')
    }
  }
}
</script>

<style lang="scss">
#project-description ul {
  padding: 0;
  list-style-type: none;
  list-style-position: inside;
}

#project-description h1,
#project-description h2,
#project-description h3,
#project-description h4,
#project-description h5,
#project-description h6 {
  font-size: 1.4rem;
  color: #111827 !important;
}

#project-description ul > li {
  padding-left: 1.875rem;
}

#project-description ul > li::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 9999px;
  border: 2px solid #0ea66d;

  // position the custom bullet in the middle of the first line
  top: 7px;

  // position the custom bullet in the middle of the whole list item element
  // top: 50%;
  // transform: translateY(-50%);
}
</style>
