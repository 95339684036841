import { render, staticRenderFns } from "./project-detail-interest.vue?vue&type=template&id=12a18bfa&"
import script from "./project-detail-interest.vue?vue&type=script&lang=js&"
export * from "./project-detail-interest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KIcon from '@web/components/core/k-icon'
import KInput from '@web/components/core/k-input'
import KPhone from '@web/components/core/k-phone'
import KRadio from '@web/components/core/k-radio'
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {KButton,KIcon,KInput,KPhone,KRadio,VExpandTransition,VRadioGroup})
